<!--
 * @Author: 候怀烨
 * @Date: 2020-11-16 19:29:54
 * @LastEditTime: 2021-03-23 19:36:54
 * @LastEditors: Please set LastEditors
 * @Description: 添加路线
 * @FilePath: \sd-vue-admin\src\views\project\baseOrder\aboutSatff\pathPlanning\components\addrouter.vue
-->
<template>
  <div>
    <el-button type="primary" @click="show">添加路线</el-button>
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      title="路线"
      :visible.sync="dialogVisible"
      width="30%"
      center
    >
      <div>
        <el-form ref="form" :model="form" label-width="120px">
          <el-form-item label="线路名称" style="width: 320px" prop="line_name">
            <el-input v-model.trim="form.line_name"></el-input>
          </el-form-item>

          <el-form-item label="状态" prop="is_close">
            <el-radio-group v-model="form.is_close">
              <el-radio label="1">关闭</el-radio>
              <el-radio label="0">正常</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="handlerAddandEdit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  import { number } from 'echarts/lib/export'
  export default {
    data() {
      return {
        dialogVisible: false,
        form: {
          line_name: '',
          is_close: '0',
        },
        url: {
          addroute: '/baseAdmin/line/add',
          editroute: '/baseAdmin/line/update',
          xiangqing: '/baseAdmin/line/info',
        },
        edit: 0,
      }
    },
    watch: {
      dialogVisible(val) {
        if (val == false) {
          this.$refs['form'].resetFields()
          this.edit = 0
        }
      },
    },
    methods: {
      close() {
        // this.$refs['form'].resetFields()
        this.dialogVisible = false
        // this.$emit('shuxin')
      },
      show() {
        console.log('lalal')
        this.dialogVisible = true
        delete this.form.line_id
      },
      handleClose(done) {
        this.$confirm('确认关闭？')
          .then((_) => {
            done()
            this.close()
          })
          .catch((_) => {})
      },
      handlereditdata(id) {
        postAction(this.url.xiangqing, { line_id: id })
          .then((res) => {
            console.log(res.data, '编辑线路转态')
            this.form = res.data
          })
          .catch((err) => {
            console.log(err, '详情失败')
          })
      },
      handlerAddandEdit() {
        if (this.edit == 0) {
          postAction(this.url.addroute, this.form)
            .then((res) => {
              console.log(res, '添加成功')
              if (res.code == 200) {
                this.dialogVisible = false
                this.$emit('shuxin')
                this.$message({
                  showClose: true,
                  message: res.msg,
                  type: 'success',
                })
              }
            })
            .catch((err) => {
              console.log(err, '添加失败')
            })
        } else if (this.edit == 1) {
          postAction(this.url.editroute, this.form)
            .then((res) => {
              console.log(res, '添加成功')
              this.dialogVisible = false
              this.$emit('shuxin')
              if (res.code == 200) {
                this.$message({
                  showClose: true,
                  message: res.msg,
                  type: 'success',
                })
              }
            })
            .catch((err) => {
              console.log(err, '添加失败')
            })
          this.edit = 0
        }
      },
    },
  }
</script>

<style></style>
