<!--
 * @Author: 候怀烨
 * @Date: 2020-11-11 14:09:29
 * @LastEditTime: 2021-05-14 14:42:43
 * @LastEditors: Please set LastEditors
 * @Description: 线路规划
 * @FilePath: \sd-vue-admin\src\views\project\baseOrder\aboutSatff\pathPlanning\index.vue
-->
<template>
  <!-- NAME[epic=基础] 线路规划 -->
  <div class="wrapper">
    <el-container>
      <el-aside class="Aside" width="300px">
        <el-row
          type="flex"
          class="row-bg"
          justify="end"
          style="padding: 0px 0px 20px 0px"
        >
          <add-router
            ref="addandedit"
            :edit="edit"
            style="margin-right: 10px"
            @shuxin="handlerRouter"
          ></add-router>
          <el-button type="primary" icon="el-icon-edit" @click="handleredit">
            编辑
          </el-button>
          <el-button type="danger" icon="el-icon-delete" @click="dler">
            删除
          </el-button>
        </el-row>
        <el-col>
          <u-table
            height="550"
            :data="tableData"
            border
            style="width: 100%"
            :row-class-name="tableRowClassName"
            @row-click="routerClass"
          >
            <!-- <u-table-column label="排序" align="center">
              <template #default="{ row }">123</template>
            </u-table-column> -->
            <u-table-column
              v-for="(tables, index) in tableList"
              :key="index"
              :prop="tables.prop"
              :label="tables.label"
              :width="tables.width"
              :align="tables.align"
            >
              <template #default="{ row }">
                <div v-if="tables.prop == 'sort'">
                  <el-input
                    v-model.number="row.sort"
                    @change="sortChange($event, row)"
                  ></el-input>
                </div>
                <div v-else>
                  {{ row[tables.prop] }}
                </div>
              </template>
            </u-table-column>
          </u-table>
        </el-col>
      </el-aside>
      <el-main class="Main">
        <el-row
          type="flex"
          class="row-bg"
          justify="end"
          style="padding: 0px 0px 20px 0px"
        >
          <el-row style="padding: 0px 20px 0px 0px">
            <map-baidu :arkers="mapp"></map-baidu>
          </el-row>
        </el-row>
        <u-table
          v-loading="loading"
          :data="list"
          use-virtual
          height="450"
          border
          :data-changes-scroll-top="false"
          style="width: 100%"
        >
          <u-table-column
            type="index"
            width="80"
            align="center"
          ></u-table-column>
          <!-- 排序 -->
          <u-table-column width="80" label="排序" align="center" prop="sort">
            <template #default="{ row }">
              <el-input v-model.trim.number="row.sort" @blur="blur_"></el-input>
              <!-- {{ row.sort }} -->
            </template>
          </u-table-column>

          <u-table-column
            prop="name"
            label="客户名称"
            width="220"
            align="center"
          >
            <template #default="{ row, $index }">
              <el-autocomplete
                v-model="row.cust_name"
                class="inline-input"
                popper-class="selectDC"
                :fetch-suggestions="querySearch"
                value-key="goods_name"
                placeholder="请输入客户名称、简拼"
                @select="handleSelect($event, row)"
                @input="handleInput"
              >
                <i
                  v-if="search"
                  slot="suffix"
                  class="el-input__icon el-icon-search"
                  @click="iconClick(row, $index)"
                ></i>
                <template slot-scope="{ item }">
                  {{ item.cust_name }}
                </template>
              </el-autocomplete>
              <!-- <el-input v-model="row.cust_name"></el-input> -->
              <!-- <el-select
                v-model="row.cust_id"
                filterable
                placeholder="请选择"
                @change="SelectChange($index)"
              >
                <el-option
                  v-for="(item, index) in optionsed"
                  :key="index"
                  :label="item.cust_name"
                  :value="item.id"
                ></el-option>
              </el-select> -->
            </template>
          </u-table-column>
          <u-table-column
            v-for="(routers, index) in routerList"
            :key="index"
            :prop="routers.prop"
            :label="routers.label"
            :width="routers.width"
            :align="routers.align"
          ></u-table-column>
          <u-table-column
            prop=""
            align="center"
            label="操作"
            fixed="right"
            min-width="160px"
          >
            <template slot-scope="scope">
              <el-button
                style="margin-right: 10px"
                type="text"
                @click.native.prevent="addrouter(scope.$index, scope.row)"
              >
                增加
              </el-button>

              <el-popconfirm
                icon="el-icon-info"
                icon-color="red"
                title="确定删除吗？"
                @confirm="deleterouter(scope.$index, scope.row)"
              >
                <el-button slot="reference" type="text">删除</el-button>
              </el-popconfirm>
            </template>
          </u-table-column>
        </u-table>
        <el-row type="flex" class="row-bg" justify="end">
          <el-row class="num">
            <p>完成天数要求：</p>
            <el-input
              v-model="value"
              type="number"
              :min="0"
              clearable
              placeholder="请输入天数"
              style="width: 130px"
            ></el-input>
            <el-row style="padding: 0px 0px 0px 20px">
              <el-button type="primary" @click="submit">保存</el-button>
            </el-row>
          </el-row>
        </el-row>
      </el-main>
    </el-container>
    <client-batch-add
      ref="clientBatchAdd"
      :type="2"
      is-line
      @batch-add-client="batchAddClient"
      @choose-row="chooseRow"
    ></client-batch-add>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  import AddRouter from './components/addrouter'
  import MapBaidu from './components/mapbaidu'
  // import { json } from 'body-parser'
  // 批量添加客户
  import ClientBatchAdd from '@/views/project/sale/control/setPrice/components/linkClient/batchAdd/index.vue'
  import _ from 'lodash'
  export default {
    name: 'PathPlanning',
    components: {
      AddRouter,
      MapBaidu,
      ClientBatchAdd,
    },
    data() {
      return {
        // 显示客户搜索图标
        search: true,
        edit: 0,
        value: '',
        tableData: [],
        tableClass: 0,
        loading: false,
        routerData: [],
        options: [], //客户列表
        tableList: [
          {
            prop: 'sort',
            label: '排序',
            width: '',
            align: 'center',
          },
          {
            prop: 'line_name',
            label: '路线',
            width: '160',
            align: 'center',
          },
        ],
        mapp: [],
        routerList: [
          {
            prop: 'address',
            label: '地址',
            width: '220',
            align: 'center',
          },
          {
            prop: 'boss',
            label: '老板姓名',
            width: '150',
            align: 'center',
          },
          {
            prop: 'mobile',
            label: '电话号码',
            width: '130',
            align: 'center',
          },
        ],
        url: {
          Router: '/baseAdmin/line/index',
          Details: '/baseAdmin/line/detail',
          delete: '/baseAdmin/line/del',
          kehu: '/baseAdmin/customer-info/index',
          addcust: '/baseAdmin/line/add-cust',
        },
        fanli: [
          {
            id: '',
            cust_id: '',
            cust_name: '',
            boss: '',
            mobile: '',
            address: '',
            sort: '',
            latitude: '',
            longitude: '',
          },
        ],
      }
    },
    computed: {
      optionsed() {
        return this.options
      },
      list: {
        get() {
          if (this.routerData.length > 0) {
            return this.routerData
          } else {
            return [
              JSON.parse(JSON.stringify(...this.fanli)),
              ...this.routerData,
            ]
          }
        },
        set(v) {
          this.routerData = v
        },
      },
    },
    created() {
      this.sort_()
    },
    mounted() {
      this.handlerRouter()
      // this.kehulist()
    },
    methods: {
      shuxin() {
        this.handlerRouter()
        // this.kehulist()
      },
      sortChange(v, row) {
        console.log('v', v)
        console.log('row', row)
        row.sort = v
        postAction('/baseAdmin/line/line-sort', {
          user_id: '',
          line_id: row.line_id,
          sort_order: v,
        }).then((res) => {
          console.log('res', res)
          this.$message.success(res.msg)
          this.handlerRouter()
        })
      },
      //保存
      submit() {
        if (this.value != '') {
          var s = []
          console.log(this.list, '这个是什么')
          this.list.forEach((item) => {
            s.push(item.cust_id)
          })
          const line = {
            line_id: this.tableData[this.tableClass].line_id,
            cust_ids: JSON.stringify(s),
            tian: this.value,
          }
          postAction(this.url.addcust, line)
            .then((res) => {
              console.log(res, '保存成功')
              if (res.code == 200) {
                this.$message({
                  message: res.msg,
                  type: 'success',
                })
              }
            })
            .catch((err) => {
              console.log(err, '失败')
            })
        } else {
          const h = this.$createElement
          this.$message({
            message: h('p', null, [
              h('span', null, '完成天数要求不能为空 '),
              h('i', { style: 'color: teal' }, '请补充'),
            ]),
          })
        }
      },
      // 排序输入框失去焦点 排序
      blur_() {
        this.sort_()
      },
      // 排序
      sort_() {
        this.list = _.sortBy(this.list, (item) => item.sort)
      },
      SelectChange(e) {
        console.log(e, '选中的值')
        console.log(this.list[e].id, '')
        this.options.forEach((element) => {
          if (this.list[e].cust_id == element.id) {
            console.log(element, '')
            this.list[e].cust_name = element.cust_name
            this.list[e].boss = element.boss
            this.list[e].mobile = element.mobile
            this.list[e].address = element.area_name
            this.list[e].latitude = element.latitude
            this.list[e].longitude = element.longitude
          }
        })
      },
      batchAddClient(val) {
        val.forEach((item) => {
          this.routerData.push({
            sort: 0,
            cust_name: item.cust_name,
            cust_id: item.cust_id,
            mobile: item.mobile,
            address: item.address,
            boss: item.boss,
          })
        })
      },
      chooseRow(val, index) {
        console.log(val, index)
        this.$set(this.routerData[index], 'cust_name', val.cust_name)
        this.$set(this.routerData[index], 'cust_id', val.cust_id)
        this.$set(this.routerData[index], 'mobile', val.mobile)
        this.$set(this.routerData[index], 'address', val.address)
        this.$set(this.routerData[index], 'boss', val.boss)
      },
      kehulist() {
        this.loading = true
        postAction(this.url.kehu, { is_closed: 0, pageSize: 1 })
          .then((res) => {
            console.log(res, '成功')
            this.options = res.data
            console.log(this.options, 'lalal')
            this.loading = false
          })
          .catch((err) => {
            console.log(err, '失败')
            this.loading = false
          })
      },
      dler() {
        this.$confirm('此操作将永久删除该线路, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            postAction(this.url.delete, {
              line_ids: this.tableData[this.tableClass].line_id,
            })
              .then((res) => {
                console.log(res, '成功')
                this.$message({
                  showClose: true,
                  message: res.msg,
                  type: 'success',
                })
                this.shuxin()
                this.handlerRouter()
              })
              .catch((err) => {
                console.log(err, '失败')
              })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            })
          })
      },
      handleredit() {
        this.$refs.addandedit.dialogVisible = true
        this.$refs.addandedit.edit = 1
        console.log(
          this.tableData[0],
          this.tableData[this.tableClass],
          this.tableClass,
          '这个是id吗'
        )
        this.$refs.addandedit.handlereditdata(
          this.tableData[this.tableClass].line_id
        )
      },
      handlerRouter() {
        postAction(this.url.Router, {})
          .then((res) => {
            console.log(res, '线路成功')
            this.tableData = res.data
            this.tableClass = 0
            this.handlerDetails(this.tableData[this.tableClass])
          })
          .catch((err) => {
            console.log(err, '线路失败')
          })
      },
      routerClass(row) {
        console.log(row, '样式')
        for (let i = 0; i < this.tableData.length; i++) {
          if (this.tableData[i].line_id == row.line_id) {
            this.tableClass = i
          }
        }
        this.handlerDetails(row)
        this.value = ''
      },
      // 选中客户下拉的操作
      handleSelect(val, row) {
        console.log('val', val)
        row.cust_name = val.cust_name
        Object.assign(row, val)
        row.address = val.area_name
        row.cust_id = val.id
      },
      handleInput(val) {
        console.log(val)
      },
      iconClick(row, index) {
        console.log('111', row)
        this.$refs.clientBatchAdd.id = row.id
        this.$refs.clientBatchAdd.index = index

        this.$refs.clientBatchAdd.showDialog = true
      },
      querySearch(queryString, cb) {
        console.log('queryString', queryString)
        if (queryString == '') {
          cb([])
        } else {
          postAction(this.url.kehu, {
            is_closed: 0,
            pageNo: 1,
            pageSize: -1,
            keyword: queryString,
          }).then((val) => {
            console.log(val)
            cb(val.data)
          })
        }
      },
      //线路详情
      handlerDetails(row) {
        postAction(this.url.Details, { line_id: row.line_id })
          .then((res) => {
            console.log(res, '线路详情')
            this.routerData = res.data.cust.rows
            this.value = res.data.tian
            var a = []
            this.routerData.forEach((list) => {
              var b = {
                lng: list.longitude,
                lat: list.latitude,
              }
              a.push(b)
            })
            this.mapp = a
          })
          .catch((err) => {})
      },
      tableRowClassName({ row, rowIndex }) {
        if (rowIndex === this.tableClass) {
          return 'warning-row'
        } else {
          return 'success-row'
        }
      },
      addrouter(index, row) {
        console.log(index, row, '增加')
        this.list.splice(
          index + 1,
          0,
          JSON.parse(JSON.stringify(this.fanli[0]))
        )
        console.log(this.list, '')
      },
      deleterouter(index, row) {
        console.log(this.list.length, index, row, '删除')
        if (this.list.length == 1) {
          this.$notify({
            title: '警告',
            message: '客官不能再删除了！',
            type: 'warning',
          })
        } else {
          this.list.splice(index, 1)
        }
      },
    },
  }
</script>
<style scoped>
  .Aside {
    align-items: center;

    padding: 20px;
    text-align: center;
  }
  .text {
    padding: 10px 20px;
    background-color: #ffffff;
    border: #000000 1px solid;
  }
  .texts {
    padding: 10px 20px;
    color: #ffffff;
    background-color: #0aaafa;
    border: #000000 1px solid;
  }
  /* .warning-row {
    color: #ffffff;
    background: #328ffe;
  } */
  .u-table .warning-row {
    color: #ffffff;
    background: #1890ff;
  }

  .u-table .success-row {
    background: #ffffff;
  }
  .num {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 0px 0px 20px;
  }
</style>
