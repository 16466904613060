var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "el-container",
        [
          _c(
            "el-aside",
            { staticClass: "Aside", attrs: { width: "300px" } },
            [
              _c(
                "el-row",
                {
                  staticClass: "row-bg",
                  staticStyle: { padding: "0px 0px 20px 0px" },
                  attrs: { type: "flex", justify: "end" },
                },
                [
                  _c("add-router", {
                    ref: "addandedit",
                    staticStyle: { "margin-right": "10px" },
                    attrs: { edit: _vm.edit },
                    on: { shuxin: _vm.handlerRouter },
                  }),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", icon: "el-icon-edit" },
                      on: { click: _vm.handleredit },
                    },
                    [_vm._v(" 编辑 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger", icon: "el-icon-delete" },
                      on: { click: _vm.dler },
                    },
                    [_vm._v(" 删除 ")]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                [
                  _c(
                    "u-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        height: "550",
                        data: _vm.tableData,
                        border: "",
                        "row-class-name": _vm.tableRowClassName,
                      },
                      on: { "row-click": _vm.routerClass },
                    },
                    _vm._l(_vm.tableList, function (tables, index) {
                      return _c("u-table-column", {
                        key: index,
                        attrs: {
                          prop: tables.prop,
                          label: tables.label,
                          width: tables.width,
                          align: tables.align,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  tables.prop == "sort"
                                    ? _c(
                                        "div",
                                        [
                                          _c("el-input", {
                                            on: {
                                              change: function ($event) {
                                                return _vm.sortChange(
                                                  $event,
                                                  row
                                                )
                                              },
                                            },
                                            model: {
                                              value: row.sort,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  row,
                                                  "sort",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression: "row.sort",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _c("div", [
                                        _vm._v(
                                          " " + _vm._s(row[tables.prop]) + " "
                                        ),
                                      ]),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-main",
            { staticClass: "Main" },
            [
              _c(
                "el-row",
                {
                  staticClass: "row-bg",
                  staticStyle: { padding: "0px 0px 20px 0px" },
                  attrs: { type: "flex", justify: "end" },
                },
                [
                  _c(
                    "el-row",
                    { staticStyle: { padding: "0px 20px 0px 0px" } },
                    [_c("map-baidu", { attrs: { arkers: _vm.mapp } })],
                    1
                  ),
                ],
                1
              ),
              _c(
                "u-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.list,
                    "use-virtual": "",
                    height: "450",
                    border: "",
                    "data-changes-scroll-top": false,
                  },
                },
                [
                  _c("u-table-column", {
                    attrs: { type: "index", width: "80", align: "center" },
                  }),
                  _c("u-table-column", {
                    attrs: {
                      width: "80",
                      label: "排序",
                      align: "center",
                      prop: "sort",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("el-input", {
                              on: { blur: _vm.blur_ },
                              model: {
                                value: row.sort,
                                callback: function ($$v) {
                                  _vm.$set(
                                    row,
                                    "sort",
                                    _vm._n(
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  )
                                },
                                expression: "row.sort",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("u-table-column", {
                    attrs: {
                      prop: "name",
                      label: "客户名称",
                      width: "220",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          var $index = ref.$index
                          return [
                            _c(
                              "el-autocomplete",
                              {
                                staticClass: "inline-input",
                                attrs: {
                                  "popper-class": "selectDC",
                                  "fetch-suggestions": _vm.querySearch,
                                  "value-key": "goods_name",
                                  placeholder: "请输入客户名称、简拼",
                                },
                                on: {
                                  select: function ($event) {
                                    return _vm.handleSelect($event, row)
                                  },
                                  input: _vm.handleInput,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var item = ref.item
                                        return [
                                          _vm._v(
                                            " " + _vm._s(item.cust_name) + " "
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value: row.cust_name,
                                  callback: function ($$v) {
                                    _vm.$set(row, "cust_name", $$v)
                                  },
                                  expression: "row.cust_name",
                                },
                              },
                              [
                                _vm.search
                                  ? _c("i", {
                                      staticClass:
                                        "el-input__icon el-icon-search",
                                      attrs: { slot: "suffix" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.iconClick(row, $index)
                                        },
                                      },
                                      slot: "suffix",
                                    })
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._l(_vm.routerList, function (routers, index) {
                    return _c("u-table-column", {
                      key: index,
                      attrs: {
                        prop: routers.prop,
                        label: routers.label,
                        width: routers.width,
                        align: routers.align,
                      },
                    })
                  }),
                  _c("u-table-column", {
                    attrs: {
                      prop: "",
                      align: "center",
                      label: "操作",
                      fixed: "right",
                      "min-width": "160px",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-right": "10px" },
                                attrs: { type: "text" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.addrouter(
                                      scope.$index,
                                      scope.row
                                    )
                                  },
                                },
                              },
                              [_vm._v(" 增加 ")]
                            ),
                            _c(
                              "el-popconfirm",
                              {
                                attrs: {
                                  icon: "el-icon-info",
                                  "icon-color": "red",
                                  title: "确定删除吗？",
                                },
                                on: {
                                  confirm: function ($event) {
                                    return _vm.deleterouter(
                                      scope.$index,
                                      scope.row
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { slot: "reference", type: "text" },
                                    slot: "reference",
                                  },
                                  [_vm._v("删除")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                2
              ),
              _c(
                "el-row",
                {
                  staticClass: "row-bg",
                  attrs: { type: "flex", justify: "end" },
                },
                [
                  _c(
                    "el-row",
                    { staticClass: "num" },
                    [
                      _c("p", [_vm._v("完成天数要求：")]),
                      _c("el-input", {
                        staticStyle: { width: "130px" },
                        attrs: {
                          type: "number",
                          min: 0,
                          clearable: "",
                          placeholder: "请输入天数",
                        },
                        model: {
                          value: _vm.value,
                          callback: function ($$v) {
                            _vm.value = $$v
                          },
                          expression: "value",
                        },
                      }),
                      _c(
                        "el-row",
                        { staticStyle: { padding: "0px 0px 0px 20px" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.submit },
                            },
                            [_vm._v("保存")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("client-batch-add", {
        ref: "clientBatchAdd",
        attrs: { type: 2, "is-line": "" },
        on: {
          "batch-add-client": _vm.batchAddClient,
          "choose-row": _vm.chooseRow,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }