<!--
 * @Author: 候怀烨
 * @Date: 2020-11-18 11:08:17
 * @LastEditTime: 2020-12-14 15:00:11
 * @LastEditors: Please set LastEditors
 * @Description: 百度地图苗点
 * @FilePath: \sd-vue-admin\src\views\project\baseOrder\aboutSatff\pathPlanning\components\mapbaidu.vue
-->
<template>
  <div>
    <el-button type="primary" @click="dialogVisible = true">查看地图</el-button>

    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      title="查看地图"
      :visible.sync="dialogVisible"
      width="50%"
    >
      <div>
        <baidu-map
          class="map"
          :center="center"
          :zoom="zoom"
          :scroll-wheel-zoom="true"
          @ready="handler"
        >
          <bml-marker-clusterer :average-center="true">
            <bm-marker
              v-for="marker in arkers"
              :key="marker.lng"
              :dragging="true"
              animation="BMAP_ANIMATION_BOUNCE"
              :position="{ lng: marker.lng, lat: marker.lat }"
              @mousedown="clickmap"
            ></bm-marker>
          </bml-marker-clusterer>

          <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
          <bm-geolocation
            anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
            :show-address-bar="true"
            :auto-location="true"
            @locationSuccess="getLoctionSuccess"
          ></bm-geolocation>
          <bm-city-list anchor="BMAP_ANCHOR_TOP_LEFT"></bm-city-list>
        </baidu-map>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { BmlLushu, BmlMarkerClusterer, BmMarker } from 'vue-baidu-map'
  export default {
    components: {
      BmlMarkerClusterer,
      BmMarker,
    },
    props: {
      arkers: {
        type: Array,
        default() {
          return []
        },
      },
    },

    data() {
      return {
        dialogVisible: false,
        center: { lng: 0, lat: 0 },
        zoom: 10,
      }
    },
    methods: {
      clickmap(type, target) {
        console.log(type, target, '')
      },
      handler({ BMap, map }) {
        let _this = this
        // 设置一个临时变量指向vue实例，因为在百度地图回调里使用this，指向的不是vue实例；
        var geolocation = new BMap.Geolocation()
        geolocation.getCurrentPosition(
          function (r) {
            console.log(r)
            _this.center = { lng: r.longitude, lat: r.latitude } // 设置center属性值
            _this.autoLocationPoint = { lng: r.longitude, lat: r.latitude } // 自定义覆盖物
            _this.initLocation = true
          },
          { enableHighAccuracy: true }
        )

        window.map = map
      },
      //定位成功回调
      getLoctionSuccess(point, AddressComponent, marker) {
        var that = this
        map.clearOverlays()
        let Icon_0 = new BMap.Icon(that.avatar, new BMap.Size(64, 64), {
          anchor: new BMap.Size(18, 32),
          imageSize: new BMap.Size(36, 36),
        })
        var myMarker = new BMap.Marker(
          new BMap.Point(point.point.lng, point.point.lat),
          { icon: Icon_0 }
        )
        map.addOverlay(myMarker)
        this.locData.longitude = point.point.lng
        this.locData.latitude = point.point.lat
      },
      reset() {
        this.play = false
      },
      handleClose(done) {
        this.$confirm('确认关闭？')
          .then((_) => {
            done()
          })
          .catch((_) => {})
      },
    },
  }
</script>

<style>
  .map {
    width: 100%;
    height: 500px;
  }
</style>
